<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
        <v-carousel
    class="hidden-sm-and-down"
    :show-arrows="false"
    hide-delimiters
    interval="12000"
    height="600"
  >
    <v-carousel-item
      v-for="(item, i) in slides"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <div class="overlay">
        <v-row class="fill-height" style="padding-top:100px;">
          <v-col cols="11" offset="1" style="color:#ffffff;">
            <v-layout wrap class="text-h2" style="color:#ffffff;">
              <b>{{ item.title }}</b>
            </v-layout>
            <p class="mt-5" style="font-size: 18px;">
              {{ item.text }}
            </p>
            <v-layout wrap>
              <!-- <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn> -->
              <h4>Session Under Development</h4>
            </v-layout>
            <!-- <v-layout wrap style="margin-top:150px;">
                      <v-col
                       v-for="category in categoryItems.slice(1, 10)"
                      :key="category.productCategoryId"
                        cols="1"
                        align="center"
                        style="border:1px solid #c4c4c4; background-color:transparent; border-radius:5px;padding:3px !important; margin:10px;"
                      >
                        <router-link :to="'/category/' + category.name +'/' + category.productCategoryId" style="color:#ffffff; text-decoration:none;">
                          <p class="mt-2">
                            <v-btn fab color="#72bd61"
                              ><v-icon size="30" color="#ffffff"
                                >mdi-pill</v-icon
                              ></v-btn
                            >
                          </p>
                          <p style="font-size:13px;">
                            {{ category.name }}
                          </p>
                        </router-link>
                      </v-col>
            </v-layout> -->
          </v-col>
        </v-row>
      </div>
    </v-carousel-item>
  
  </v-carousel>
      <v-carousel
            class="hidden-md-and-up"
            cycle
            :show-arrows="false"
            hide-delimiters
            height="200px"
            interval="10000"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            transition="fade"
            reverse-transition="fade"
            >
            <div class="overlay">
                <v-row class="fill-height pt-5">
                <v-col cols="8" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h6" style="color:#ffffff;">
                    {{ item.title }}
                    </v-layout>
                    <p>
                    {{ item.text }}
                    </p>
                    <v-layout wrap>
                    <!-- <v-btn rounded color="#72bd61" dark small>{{
                        item.btnText
                    }}</v-btn> -->
                    <h4>Session Under Development</h4>
                    </v-layout>
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
      </v-carousel>

      <v-container fluid>
        

      </v-container>

      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "About",


  data: () => ({
      slides: [
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799385/Slider-3_bczg17.png",
          title: "Speak to our experts",
          text: "If you'd like to understand your body, our registered experts are here to help.",
          btnText: "Book",
          link: "#",
        }
      ],
       categoryItems: null,
  }),
  created() {
    this.getProductCat();
  },
  methods: {
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.6;
}

li {
  line-height: 1.6;
}

</style>
